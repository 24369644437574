.app {
  &.main {
    display: flex;
    width: 100%;
  }

  &.title {
    flex-grow: 1;
    font-size: 50px;
    padding: 50px;
  }

  &.calculator {
    display: flex;
    width: 700px;
    flex-direction: column;
    flex-shrink: 0;
  }
}
