@import './reset.css';

html {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 30px;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

@import './app';
@import './button';
@import './button-panel';
@import './display';
@import './quote';
@import './navbar';
@import './home';
